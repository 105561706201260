<template>
  <div>
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>

    <SectionHeading classname="text-center" text="How It Wor"  isMargin="mb-3 mt-5"></SectionHeading>

    <!-- 顶部 -->

    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <p style="text-align: center;">&nbsp;&nbsp;
          <img src="images/howitwork1.png">
        </p>
      </div>
    </section>



    <Footer classname="bg-black on-dark"></Footer>
    <!-- Footer End -->
    <!--    <Switcher/>-->
    <!-- Back to top -->
<!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
<!--       v-scroll-to="'#topnav'">-->
<!--&lt;!&ndash;      <arrow-up-icon class="icons"></arrow-up-icon>&ndash;&gt;-->
<!--    </a>-->
    <!-- Back to top -->
  </div>
</template>

<script>

import {

  ArrowUpIcon,
} from "vue-feather-icons";
import storage from "@/storage";




/**
 * Index-1
 */
export default {
  name: "HowItWork",
  metaInfo: {
    title: 'NFT INVESTMENT PLATFORM',
    link: [
      { rel: 'canonical', href: 'https://***.com/how-it-work' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    ArrowUpIcon,
  },
  created() {
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    }
  }
};
</script>


